<template>
  <c-box
    :background-color="['#F2F2F2', '#FFF']"
    padding-bottom="16px"
    width="100%"
  >
    <c-box
      position="relative"
      mx="auto"
      :background-color="['#FFF', '#FFF']"
      border="1px solid #f2f2f2"
      :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
      :border-radius="['0px', '16px']"
      :min-height="['calc(100vh - 62px)', '74vh']"
      width="100%"
      max-width="1270px"
    >
      <c-box :px="['0px', '85px']">
        <c-box
          pt="20px"
          pb="10px"
          justify-content="center"
          :d="['none', 'flex']"
        >
          <c-box
            :font-size="['18px', '28px']"
            font-weight="bold"
          >
            Notifikasi
          </c-box>
        </c-box>  
        <c-box
          as="hr"
          w="100%"
          border-top="1px solid #0000001A"
        />
        <NotificationItem
          :notifications="fetchPopupNotification.data.value"
          style="max-width: none;"
          class="icon-notif"
          :ellipsis="false"
          @onClick="handleClickNotification"
        />
      </c-box>
    
      <ModalInfo
        for="infoNotitifcation"
        :close-on-overlay-click="false"
        :is-open="isOpenModal"
        :image="modalNotification.image"
        :text="modalNotification.text"
        :button-text="modalNotification.buttonText"
        :button-link="modalNotification.buttonLink"
        :with-button-close="modalNotification.buttonClose"
        @close="handleCloseSuccessModal"
      />  
    </c-box>
  </c-box>
</template>

<script >
import ModalInfo from '@/components/widgets/modal-info/index.vue';
import NotificationItem from '@/components/header/elements/notification-item.vue';
import { useActions, useMutations } from '@/stores';
import { MILISECONDS_FOR } from '@/constants/miliseconds';
import { useQuery } from '@tanstack/vue-query';
import { useNotification } from '@/components/header/composables/use-notification';
import { onMounted, ref, reactive } from '@vue/composition-api';
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const mutation = useMutations('general', ['setHeaderConfig']);
  onMounted(() => {
    mutation.setHeaderConfig({
      type: 'menu-only-back',
      title: 'Notifikasi'
    });
  });
  const {
    handleReadNotification
  } = useNotification();
  const {
    loadNotifications
  } = useActions('clients', ['loadNotifications']);
  const fetchPopupNotification = useQuery({
    queryKey: ['useFetchPopupNotifications'],
    queryFn: () => loadNotifications(),
    staleTime: MILISECONDS_FOR.ONE_DAY
  });
  const isOpenModal = ref(false);
  const modalNotification = reactive({
    image: '',
    text: '',
    buttonText: '',
    buttonLink: '',
    buttonClose: false
  });

  const handleClickNotification = notification => {
    if (notification?.popupId || notification?.popup?.id) {
      modalNotification.image = notification?.popup?.object?.image;
      modalNotification.text = notification?.popup?.object?.text;
      modalNotification.buttonText = notification?.popup?.object?.buttonText;
      modalNotification.buttonLink = notification?.popup?.object?.buttonLink;
      modalNotification.buttonClose = notification?.popup?.object?.buttonClose ? true : false;
      isOpenModal.value = true;
    }

    handleReadNotification(notification);
  };

  const handleCloseSuccessModal = () => {
    isOpenModal.value = false;
  };

  return {
    fetchPopupNotification,
    isOpenModal,
    modalNotification,
    handleClickNotification,
    handleCloseSuccessModal
  };
};

__sfc_main.components = Object.assign({
  NotificationItem,
  ModalInfo
}, __sfc_main.components);
export default __sfc_main;
</script>

<style scoped>
.icon-notif::v-deep li div div svg{
  height: 16px;
  width: 16px;
}

@media(min-width: 767px) {
  .icon-notif::v-deep li div div svg{
    height: 22px;
    width: 22px;
  }
}
</style>